import {
  Center,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import Layout from "../../components/Layout";
import { useMoodData } from "../../data/moodApi";
import MoodTrackerButton from "../../components/dashboard/MoodTrackerButton";
import MoodTrackerScreen from "../../components/MoodTrackerScreen";
import Card from "../../components/Card";
import SearchComponent from "../../components/SearchComponent";
import LockedOverlay from "../../components/LockedOverlay";
import { useModuleProgress } from "../../data/modulesAPI";
import InstructionText from "../../components/InstructionText";

const MoodTracker = () => {
  const [isMobile] = useMediaQuery("(max-width: 540px)");
  const { data, isLoading, refetch } = useMoodData();
  const { data: progress } = useModuleProgress("4");

  const locked = useMemo(() => {
    return (progress?.completed ?? 0) <= 3;
  }, [progress]);

  useEffect(() => {
    document.title = "Mood Tracker";
  }, []);

  return (
    <main aria-label="Mood tracker">
      <Layout>
        <Stack spacing={5}>
          <Flex p={isMobile ? 3 : 0}>
            <Center w="full">
              <Heading as="h1" mb={3}>
                Mood Tracker
              </Heading>
              <Spacer />
              <InstructionText
                title={"About Mood Tracker"}
                mdId={"instruction-text/moodtracker"}
              />
              {
                isMobile ? (
                  <SearchComponent asInput={false} dark={false} />
                ) : null
                // <AddLink refetch={refetch} />
              }
            </Center>
          </Flex>
          <Card position="relative">
            {progress ? (
              <>
                {locked && <LockedOverlay />}
                <Stack spacing={10}>
                  <MoodTrackerButton onMoodTracked={refetch} />
                  <MoodTrackerScreen data={data} isLoading={isLoading} />
                </Stack>
              </>
            ) : (
              <Center p={5}>
                <Spinner />
              </Center>
            )}
          </Card>
        </Stack>
      </Layout>
    </main>
  );
};

export default MoodTracker;

import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { cleanRawBody } from "../data/util";
import { BsInfo } from "react-icons/bs";

interface Props {
  title: string;
  mdId: string;
  dark?: boolean;
}

const InstructionText = ({ title, mdId, dark }: Props) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [found, setFound] = React.useState(false);

  const bgColor = useColorModeValue(dark ? "#505480" : "white", "#505480");
  const color = useColorModeValue(dark ? "white" : "#505480", "white");

  const { allMdx } = useStaticQuery(graphql`
    query MyQuery {
      allMdx(filter: { slug: { regex: "/instruction-text/" } }) {
        edges {
          node {
            frontmatter {
              id
            }
            rawBody
          }
        }
      }
    }
  `);

  const text = useMemo(() => {
    try {
      const body = cleanRawBody(
        allMdx.edges.filter(
          (page: { node: { frontmatter: { id: string } } }) => {
            return page.node.frontmatter.id === mdId;
          }
        )[0].node.rawBody,
        false,
        false,
        true
      );
      if (body.trim().length > 0) setFound(true);
      return body;
    } catch (e) {
      return "Help text not found. Sorry :(";
    }
  }, [allMdx]);

  return (
    <>
      {found ? (
        <IconButton
          aria-label={"instructions"}
          icon={<BsInfo size="60%" />}
          onClick={onToggle}
          m={2}
          bgColor={bgColor}
          color={color}
          isRound
          size="lg"
          shadow="md"
          width={["40px", "40px", "40px", "40px", "40px", "40px"]}
        />
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader mx={4} mt={3}>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody m={4}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InstructionText;

import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";
import React from "react";

type Props = BoxProps & {
  children?: React.ReactNode;
};

const Card = ({ children, ...boxProps }: Props) => {
  const bg = useColorModeValue("white", "gray.800");

  return (
    <Box
      p={10}
      h="full"
      w="full"
      flex={1}
      bg={bg}
      rounded="3xl"
      {...boxProps}
      overflow="hidden"
    >
      {children}
    </Box>
  );
};

export default Card;

import {
  Box,
  Center,
  Flex,
  theme,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { MoodData, useMoodData } from "../data/moodApi";

type Props = {
  ratio?: number;
  moodHistory?: MoodData[];
  onMoodSelected?: (data: MoodData) => void;
};

const MoodGraph = ({ ratio = 5 / 3, moodHistory, onMoodSelected }: Props) => {
  
  const { colors } = useTheme();
  const [selectedMoodId, setSelectedMoodId] = useState<number>();

  useEffect(() => {
    setSelectedMoodId((moodHistory ?? []).length - 1);
  }, [moodHistory]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    aspectRatio: ratio,
    scales: {
      y: {
        beginAtZero: true,
        max: 5,
        min: -5,
        ticks: {
          color: useColorModeValue(colors.gray[600], colors.gray[400]),
          callback: (value: number) => {
            if (value === 5) return "Elevated mood 5";
            if (value === 0) return "Balanced 0";
            if (value === -5) return "Low -5";
            return `${value}`;
          },
        },
        grid: {
          color: useColorModeValue("rgba(0,0,0,0.1)", "rgba(255,255,255,0.1)"),
          borderColor: useColorModeValue(
            "rgba(0,0,0,0.5)",
            "rgba(255,255,255,0.5)"
          ),
        },
      },
      x: {
        type: "time",
        distribution: "linear",
        time: {
          unit: "day",
          displayFormats: {
            day: "MMM D",
          },
        },
        ticks: {
          color: useColorModeValue(colors.gray[600], colors.gray[400]),
        },
        grid: {
          color: useColorModeValue("rgba(0,0,0,0.1)", "rgba(255,255,255,0.1)"),
          borderColor: useColorModeValue(
            "rgba(0,0,0,0.5)",
            "rgba(255,255,255,0.5)"
          ),
        },
      },
    },

    onClick: function (evt: any, element: any) {
      if (element.length > 0) {
        var ind = element[0].index;
        if (moodHistory?.[ind]) {
          onMoodSelected?.(moodHistory?.[ind]);
          setSelectedMoodId(ind);
        }
      }
    },
    clip: false,
  };

  const data = useMemo(
    () => ({
      datasets: [
        {
          label: "Mood ",
          backgroundColor: colors["green"][500],
          borderColor: colors["green"][300],
          borderWidth: 5,
          tension: 0.2,
          data:
            moodHistory?.map((m) => ({
              x: m.date,
              y: m.mood,
            })) ?? [],
          pointBackgroundColor: function (context: any) {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
            return index === selectedMoodId
              ? colors["green"][500]
              : colors["green"][300];
          },
          pointRadius:
            moodHistory?.map((m, i) => (i === selectedMoodId ? 10 : 5)) ?? [],
          pointHoverRadius: 10,
        },
      ],
    }),
    [selectedMoodId]
  );

  return (
    <Box position="relative">
      <Box zIndex={10} position="relative">
        {/* @ts-ignore */}
        <Line options={options} data={data} />
      </Box>
    </Box>
  );
};

export default MoodGraph;

import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { MoodData } from "../data/moodApi";
import MoodDescription from "./mood-tracker/MoodDescription";
import MoodGraph from "./MoodGraph";

type Props = {
  data?: MoodData[];
  isLoading?: boolean;
};

const MoodTrackerScreen = ({ data, isLoading }: Props) => {
  const [timeView, setTimeView] = useState<"week" | "month" | "year">("week");
  const [selectedMoodId, setSelectedMoodId] = useState<string>();
  const ratio = useBreakpointValue({ base: 1 / 1, md: 3 / 1 });

  const buttonBgActive = useColorModeValue("blue.700", "gray.800");
  const buttonBgHover = useColorModeValue("blue.200", "blue.400");
  const buttonBgHoverActive = useColorModeValue("blue.500", "blue.600");
  const buttonBgInactive = "";

  const selectedMoodData = useMemo(() => {
    return data?.find((item) => item.id === selectedMoodId);
  }, [selectedMoodId, data]);

  const filteredMoodData = useMemo(() => {
    if (!data) return [];
    if (timeView === "week") {
      return data.filter((item) => moment(item.date) >= moment().subtract(1, 'weeks'));
    } else if (timeView === "month") {
      return data.filter((item) => moment(item.date) >= moment().subtract(1, 'months'));
    } else if (timeView === "year") {
      return data.filter((item) => moment(item.date) >= moment().subtract(1, 'years'));
    } else {
      return data.filter((item) => moment(item.date) >= moment().subtract(1, 'months'));
    }
  }, [data, timeView]);

  useEffect(() => {
    if (data?.length) {
      setSelectedMoodId(data[data.length - 1].id);
    }
  }, [data]);

  const bg = useColorModeValue("blue.100", "blue.800");

  return (
    <Stack spacing={10}>
      <Flex justify="space-between" alignItems="center">
        <Flex
          gap={1}
          p={1}
          rounded="md"
          bg={bg}
          direction={{ base: "row", sm: "row" }}
        >
          <Button
            size="xs"
            color={timeView === "week" ? "white" : "blue.00"}
            bg={timeView === "week" ? buttonBgActive : buttonBgInactive}
            _hover={{
              bg: timeView === "week" ? buttonBgHoverActive : buttonBgHover,
            }}
            onClick={() => setTimeView("week")}
          >
            Past week
          </Button>
          <Button
            size="xs"
            color={timeView === "month" ? "white" : "blue.00"}
            bg={timeView === "month" ? buttonBgActive : buttonBgInactive}
            _hover={{
              bg: timeView === "month" ? buttonBgHoverActive : buttonBgHover,
            }}
            onClick={() => setTimeView("month")}
          >
            Past month
          </Button>
          <Button
            size="xs"
            color={timeView === "year" ? "white" : "blue.00"}
            bg={timeView === "year" ? buttonBgActive : buttonBgInactive}
            _hover={{
              bg: timeView === "year" ? buttonBgHoverActive : buttonBgHover,
            }}
            onClick={() => setTimeView("year")}
          >
            Past year
          </Button>
        </Flex>
        <Spacer />
      </Flex>
      <MoodGraph
        moodHistory={filteredMoodData}
        onMoodSelected={(mood) => setSelectedMoodId(mood.id)}
        ratio={ratio}
      />
      <SimpleGrid
        templateColumns={[
          "1fr",
          "1fr",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        row={2}
        gap={5}
      >
        <MoodDescription mood={selectedMoodData?.mood} />
        <Box maxW="600px">
          <Heading size="sm" mb={2} mt={4}>
            Your Notes
          </Heading>
          {selectedMoodData ? (
            <>
              <Text mb={4} fontSize="sm" overflow="break-all">
                {selectedMoodData.notes.length > 0
                  ? selectedMoodData.notes
                  : "No notes"}
              </Text>
              <Flex flexWrap="wrap" gap={1}>
                {selectedMoodData?.tags.map((tag) => (
                  <Tag colorScheme="blue">{tag}</Tag>
                ))}
              </Flex>
            </>
          ) : null}
        </Box>
      </SimpleGrid>
    </Stack>
  );
};

export default MoodTrackerScreen;

import { Alert, Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  mood?: number;
};

const moodDescriptions = [
  {
    title: "Severely low",
    description:
      "People who select this option often describe feeling hopeless, like life isn’t worth living. Others report recurring thoughts of suicide or self-harm. Some may need to be hospitalised when feeling this way",
  },
  {
    title: "Very low",
    description:
      "People who select this option often describe feeling hopeless or guilty. Others feel like even small tasks are impossible. Some report thoughts of suicide or self-harm",
  },
  {
    title: "Moderately low",
    description:
      "People who select this option often describe that their thoughts feel slow and day-to-day tasks are difficult to finish. Others describe a reduced appetite. Others experience the need to sleep for much longer than normal, or that they feel sleepy but can’t drift off",
  },
  {
    title: "Slightly low",
    description:
      "People who select this option describe feelings of sadness, anxiety or panic. Others describe experiencing poor memory and that concentrating on day-to-day tasks is more difficult than normal",
  },
  {
    title: "Mostly balanced mood",
    description:
      "People who select this option often describe feeling generally fine but with difficulty concentrating. Others describe a mild tendency to feeling worried, or to avoid some social situations",
  },
  {
    title: "Balanced mood",
    description:
      "People who select this option often describe feeling most like themselves, and don’t report any feelings of extreme sadness or happiness. Others who select this option describe feeling that they are in control of their life and have the headspace they need to make important decisions",
  },
  {
    title: "Mostly balanced mood",
    description:
      "People who select this option often describe feeling generally optimistic about life and themselves, but not excessively so. Others say they are able to create and stick to plans",
  },
  {
    title: "Slightly elevated mood",
    description:
      "People who select this option often feel more productive and talkative than normal, and do more activities. Others say that they don’t need to sleep as much",
  },
  {
    title: "Moderately elevated mood",
    description:
      "People who select this option often describe racing thoughts and/or speech and that they need to sleep far less than normal. Others say they feel more energised, but due to this take on too many tasks at once and can’t finish them all",
  },
  {
    title: "Very elevated mood",
    description:
      "People who select this option often describe feeling paranoid, reckless, and detached from reality. Others say they don’t sleep very much (or at all) and have lots of energy, but can’t focus on anything for very long",
  },
  {
    title: "Severely elevated mood",
    description:
      "People who select this option often describe hallucinations, feeling delusional, or that they’ve completely lost touch with reality. Some people may need to be hospitalised when feeling this way",
  },
];

const MoodDescription = ({ mood }: Props) => {
  return (
    <Alert rounded={"xl"}>
      <Box p={2}>
        {mood !== undefined ? (
          <>
            <Heading size="xs" mb={2}>
              {moodDescriptions[mood + 5].title}
            </Heading>
            <Text fontSize="sm">{moodDescriptions[mood + 5].description}</Text>
          </>
        ) : (
          <Text fontSize="sm">Select a mood to see more details</Text>
        )}
      </Box>
    </Alert>
  );
};

export default MoodDescription;

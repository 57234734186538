import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useTrackMoodMutation } from "../../data/moodApi";
import MoodDescription from "../mood-tracker/MoodDescription";

type Props = {
  onMoodTracked?: () => void;
};

const tags = ["Depressed", "Anxious", "Stressed", "Happy", "Sad", "Angry"];

const MoodTrackerButton = ({ onMoodTracked }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [moodValue, setMoodValue] = useState(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [notes, setNotes] = useState("");
  const save = useTrackMoodMutation();

  const textColor = useColorModeValue("white", "white");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const handleSubmit = async () => {
    await save.mutateAsync({
      mood: moodValue,
      tags: selectedTags,
      notes,
      date: moment(date, "YYYY-MM-DD").toISOString(),
    });
    onMoodTracked?.();
    onClose();
  };

  const toggleTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((item) => item !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const color = useColorModeValue("white", "black");
  const blue = useColorModeValue("blue.500", "blue.300");
  const gradient = useColorModeValue(
    "linear(to-b, green.500, green.600)",
    "linear(to-b, green.700, green.800)"
  );

  let modalContent = (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>How are you feeling?</ModalHeader>
      <ModalBody>
        <Stack spacing={8} mt={"70px"}>
          <Box px={6}>
            <Slider
              aria-label="slider-ex-6"
              max={5}
              min={-5}
              value={moodValue}
              onChange={(val) => setMoodValue(val)}
            >
              <SliderMark
                value={moodValue}
                textAlign="center"
                color={blue}
                fontSize={"3em"}
                mt="-80px"
                ml="-48px"
                w="24"
              >
                {moodValue}
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>

          <MoodDescription mood={moodValue} />
          <Box>
            <Heading size="sm" mb={3}>
              Date
            </Heading>
            <Input
              type="date"
              value={date}
              onChange={(e) => {
                //check if date is in the future - if it is - do nothing
                if (moment(e.target.value).isAfter(moment())) {
                  return;
                } else {
                  setDate(e.target.value);
                }
              }}
            />
          </Box>
          <Box>
            <Heading size="sm" mb={3}>
              Would you like to add some tags to describe your mood?
            </Heading>
            <Flex gap={2} flexWrap="wrap">
              {tags.map((tag) => (
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant={selectedTags.includes(tag) ? "solid" : "outline"}
                  onClick={() => toggleTag(tag)}
                >
                  {tag}
                </Button>
              ))}
            </Flex>
          </Box>
          <Box>
            <Heading size="sm" mb={3}>
              Would you like to add some notes to describe your mood?
            </Heading>
            <Textarea
              placeholder="Add here any events or notes from today that you think may have affected your mood."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Box>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex gap={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={save.isLoading}
          >
            Add Mood
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  );

  return (
    <>
      <Flex
        p={5}
        align="center"
        gap={4}
        bgGradient={gradient}
        color={color}
        rounded="2xl"
        width={["100%", "100%", "100%", "auto"]}
        maxWidth="400px"
        onClick={onOpen}
        cursor="pointer"
      >
        <Icon color={textColor} as={AddIcon} w={8} h={8} opacity={0.75} />
        <Flex flex={1} direction="column" justify="center" align="flex-start">
          <Heading size="md" fontWeight="bold" color={textColor}>
            How are you today?
          </Heading>
          <Text color={textColor} mt={1}>
            Tap here to fill in your mood tracker
          </Text>
          <Button mt={2} size="xs" variant="outline" onClick={onOpen}>
            Get Started
          </Button>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        {modalContent}
      </Modal>
    </>
  );
};

export default MoodTrackerButton;

import { Auth } from "aws-amplify";
import { BASE_API_URL, useMutation, useQuery } from "./util";

export type MoodData = {
  id: string;
  userId: string;
  mood: number;
  tags: string[];
  notes: string;
  date: string;
};

export const editExistingMood = async (moodData: {
  id: string;
  mood: number;
  tags: string[];
  notes: string;
  date: string;
}) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const response = await fetch(`${BASE_API_URL}/mood/edit`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(moodData),
  });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error("Something went wrong");
  }
};

export const useMoodData = () => {
  return useQuery<MoodData[]>("GetMood", async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/mood`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch mood");
    }

    const data = await response.json();
    return data?.Items ?? [];
  });
};

export type TrackMoodDTO = {
  mood: number;
  tags: string[];
  notes: string;
  date?: string;
};

export const useTrackMoodMutation = () => {
  return useMutation(async (moodData: TrackMoodDTO) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/mood`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(moodData),
    });

    if (!response.ok) {
      throw new Error("Failed to track mood");
    }

    return await response.json();
  });
};

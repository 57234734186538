import React from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";

type Props = {};

const LockedOverlay = (props: Props) => {
  const bg = useColorModeValue("whiteAlpha.700", "blackAlpha.700");

  return (
    <Flex
      position="absolute"
      direction="column"
      gap={4}
      align="center"
      justify="center"
      left={0}
      top={0}
      w="100%"
      h="100%"
      bg={bg}
      zIndex={9}
    >
      <Flex
        direction="column"
        gap={4}
        align="center"
        justify="center"
        maxW={550}
        textAlign="center"
      >
        <Icon as={FaLock} w={8} h={8} />
        <Heading>Locked</Heading>
        <Text opacity={0.8}>
          You will have access to the IBPI mood tracker once you have completed
          module 4.
        </Text>
      </Flex>
    </Flex>
  );
};

export default LockedOverlay;
